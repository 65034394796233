<template>
  <div class="package-description">
    <div v-html="readme"></div>
  </div>
</template>

<script>
    export default {
        props: ['readme'],
    }
</script>
