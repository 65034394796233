window.CartJs = require('chart.js');
window.Snackbar = require('node-snackbar');
import swal from 'sweetalert';
require('./rating/rating');

$(function() {
  $('[data-toggle="tooltip"]').tooltip();

  /**
   * Update product from repository
   */
  $('#btn-update-repo').on('click', function() {
    
    $(this).attr('disabled', 'disabled');
    $(this).html('<i class="fas fa-spinner fa-pulse"></i> Updating...');
    var repoUrl = $(this).data('repo');
    
    axios.post('/member/package', {
      type: 'sync',
      repo: repoUrl
    })
    .then((json) => {
      window.location.reload();
    })
    .catch((error) => {
      
      if(error.response.status > 300 && error.response.status < 500) {
        swal({
          title: 'Error',
          text: error.response.data.message,
          icon: 'warning',
          closeOnEsc: false,
          closeOnClickOutside: false
        }).then((e) => {
          if(e) {
            window.location.reload();
          }
        });
      }

      if(error.response.status >= 500) {
        swal({
          title: 'Server Error',
          text: 'Keep calm and try again',
          icon: 'warning',
          closeOnEsc: false,
          closeOnClickOutside: false
        }).then((e) => {
          if(e) {
            window.location.reload();
          }
        });
      }
      
    });
  });

  // Markup full price
  $('[name="single_license_price"]').on('input', function() {
    let markupForm = $('[name="markup_single_project"]');
    let markupPrice = markupForm.data('markup');
    let val = $(this).val();
    let markup = parseFloat(val) * parseFloat(markupPrice) / 100;
    let price = parseFloat(val) + parseFloat(markup);
    markupForm.val(
      price.toFixed(2)
    );
  });
  // Markup version price
  $('[name="unlimited_license_price"]').on('input', function() {
    let markupForm = $('[name="markup_unlimted_project"]');
    let markupPrice = markupForm.data('markup');
    let val = $(this).val();
    let markup = parseFloat(val) * parseFloat(markupPrice) / 100;
    let price = parseFloat(val) + parseFloat(markup);
    markupForm.val(
      price.toFixed(2)
    );
  });

  $('#btn-api-reset').on('click', function() {

    swal({
      title: 'Reset API Token',
      text: 'Are you sure you want to reset API Token ?',
      buttons: ['No', 'Yes']
    })
    .then(e => {
      if(e) {
        $('#form-api-reset').submit();
      }
    });

  });


  if($('#form-profile')) {
    $('#form-profile').on('submit', function(e) {
      let oldEmail = $('#email_paypal_account').data('email');
      let newEmail = $('#email_paypal_account').val();
      let password = $('[name="config_modal_password"]').val();
      
      if(! (oldEmail === newEmail) && password === '') {
        e.preventDefault();
        $('[name="config_modal_password"]').attr('required', 'required');
        $('#confirm-modal').modal('show');
      } else {
        $('[name="config_modal_password"]').removeAttr('required');
      }

    });
  }

  $('.btn-copy').on('click', function() {
    let target = $(this).data('target');
    let input = document.querySelector(target);
    input.select();
    input.setSelectionRange(0, 99999); /* For mobile devices */
    document.execCommand("copy");
    alert('Copied to clipboard');
  });

  $('[role="load-data-server"]').each(function() {
    let ajax = $(this).data('ajax-url');
    $.get(ajax, (content) => {
      $(this).html(content);
    })
  });

  $('[role="chart-bar"]').each(function(i, canvas) {
    let ajax = $(this).data('url');
    $.getJSON(ajax, (json) => {
      let config = {
        type: 'line',
        data: {
          labels: json.labels,
          datasets: json.datasets
        },
        options: {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: ''
            },
            tooltip: {
              mode: 'index',
              intersect: false,
            }
          },
          hover: {
            mode: 'nearest',
            intersect: true
          },
          scales: {
            x: {
              display: true,
              scaleLabel: {
                display: true,
              }
            },
            y: {
              display: true,
              scaleLabel: {
                display: true,
              }
            }
          }
        }
      };
			window.myLine = new Chart(canvas.getContext('2d'), config);
    })
  });
  
  $('form').on('submit', function() {
    let pre = $(this).attr('submit-pre');
    let text = $(this).data('text');
    if(typeof pre === "undefined") {
      let textProccess = typeof text === "undefined" ? 'Processing...' : text;
      Snackbar.show({text: textProccess, pos: 'top-center', showAction: false, duration: 70000});
      $('button[type="submit"]').attr('disabled', 'disabled');
      let btnText = $('button[type="submit"]').html();
      $('button[type="submit"]').html(textProccess);

      setTimeout(() => {
        $('button[type="submit"]').removeAttr('disabled');
        $('button[type="submit"]').html(btnText);
        swal({
          title: 'Time Out',
          text: 'Keep calm and please try again'
        });
      }, 70000);
    }
  });


  $('.input-rating').rating({
    extendSymbol: function () {
      var title;
      $(this).tooltip({
        container: 'body',
        placement: 'bottom',
        trigger: 'manual',
        title: function () {
          return title;
        }
      });
      $(this).on('rating.rateenter', function (e, rate) {
        title = rate;
        $(this).tooltip('show');
      })
      .on('rating.rateleave', function () {
        $(this).tooltip('hide');
      });
    }
  });

});