<template>
  <div class="">
    <img :src="avatar" alt="" class="img-fluid mb-3">
    <button :disabled="loading" class="btn btn-block btn-primary" id="set-avatar">
       <i class="fas mr-1 fa-camera    "></i> {{ loading ? 'Uploading...' : 'Browse Image' }}
    </button>
    <avatar-cropper
        trigger="#set-avatar"
        :upload-url="`/member/package?uuid=${uuid}`"
        @uploading="handleUploading"
        @uploaded="handleUploaded"
        @completed="handleCompleted"
        @error="handlerError"
        :cropper-options="{ aspectRatio: 800 / 459 }"
        :output-options="{width: 800, height: 459}"
        :labels="{ submit: 'Upload', cancel: 'Cancel' }"
    ></avatar-cropper>
  </div>
</template>
<script>

import AvatarCropper from "vue-avatar-cropper";
import swal from 'sweetalert';

export default {
  props: ['src', 'csrf', 'uuid'],
  data() {
    return {
      loading: false,
      avatar: null
    }
  },
  components: {
    AvatarCropper
  },
  mounted() {
    this.avatar = this.src;
  },
  methods: {
    handleUploading(form, xhr) {
        form.append('_token', this.csrf);
        this.loading = true;
    },
    handleUploaded(response, form, xhr) {
        // update user avatar attribute
        this.loading = false;
        this.avatar = response.thumbnail;

        swal({
          title: 'Success',
          text: 'Thumbnail has been changed',
          icon: 'success'
        });
    },
    handleCompleted(response, form, xhr) {
        this.loading = false;
    },
    handlerError(message, type, xhr) {
      this.loading = false;
      if (type == 'upload') {
        swal({
          title: 'Failed',
          text: message,
          icon: 'warning'
        });
      }
    }
  }
}
</script>
<style lang="scss">
.opacity {
  opacity: .2;
}
.img-container {
  margin: 0 auto;
  // background: red;
  width: 110px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-upload {
  position: absolute;
  bottom: -.3rem;
  right: -.3rem;
}
</style>