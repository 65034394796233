<template>
  <div class="text-center img-container position-relative">
    <div>
      <img :src="avatar" alt="Avatar" :class="'img img-thumbnail img-avatar rounded img-fluid ' + (loading ? 'opacity' : '') ">
    </div>
    <button class="btn btn-secondary btn-sm btn-upload" id="set-avatar">
      <i class="fas fa-camera    "></i>
    </button>
    <avatar-cropper
        trigger="#set-avatar"
        upload-url="/member/profile"
        @uploading="handleUploading"
        @uploaded="handleUploaded"
        @completed="handleCompleted"
        @error="handlerError"
        :labels="{ submit: 'Upload', cancel: 'Cancel' }"
        :output-options="{width: 250, height: 250}"
    ></avatar-cropper>
  </div>
</template>
<script>

import AvatarCropper from "vue-avatar-cropper";
import swal from 'sweetalert';

export default {
  props: ['src', 'csrf'],
  data() {
    return {
      loading: false,
      avatar: null
    }
  },
  components: {
    AvatarCropper
  },
  mounted() {
    this.avatar = this.src;
  },
  methods: {
    handleUploading(form, xhr) {
        form.append('_token', this.csrf);
        this.loading = true;
    },
    handleUploaded(response, form, xhr) {
        // update user avatar attribute
        this.loading = false;
        this.avatar = response.avatar;
    },
    handleCompleted(response, form, xhr) {
        this.loading = false;
    },
    handlerError(message, type, xhr) {
      this.loading = false;
      if (type == 'upload') {
        swal({
          title: 'Failed',
          text: message,
          icon: 'warning'
        });
      }
    }
  }
}
</script>
<style lang="scss">
.opacity {
  opacity: .2;
}
.img-container {
  margin: 0 auto;
  // background: red;
  width: 110px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-upload {
  position: absolute;
  bottom: -.3rem;
  right: -.3rem;
}
</style>