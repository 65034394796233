<template>
    <div>
        <div class="mb-3">
            <button class="btn btn-primary">Update Repository</button>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['uid'],
        mounted() {
            console.log('Component mounted.')
        }
    }
</script>
