<template>
  <div>
    
    <div class="alert alert-warning" v-if="alert">
      <button @click="closeAlert" type="button" class="close">
        <span aria-hidden="true">&times;</span>
      </button>
      <p>{{ alert }}</p>
    </div>

    <form action="" @submit.prevent="checkRepository">
      <div class="form-group">
        <label for="title" class="font-weight-bold">Private Repository URL (Github / Gitlab) *</label>
        <input type="url" v-model="url" class="form-control" placeholder="e.g: https://github.com/composer/composer" required>
      </div>

      <div class="text-right">
        <button :disabled="loading" class="btn btn-primary">{{ loading ? 'Checking...' : 'Check Repository &rarr;' }}</button>
      </div>
    </form>
  </div>
</template>
<script>

import swal from 'sweetalert';

export default {
  data() {
    return {
      loading: false,
      url: '',
      alert: '',

      product: {}
    }
  },
  methods: {
    closeAlert() {
      this.alert = '';
    },
    checkRepository() {
      this.loading = true;
      axios.post('/member/package', { type: 'fetch', repo: this.url })
        .then((json) => {
          this.loading = false;
          swal({
            title: json.data.repo.full_name,
            text: 'Packages available, do you want to continue?',
            buttons: ['No', 'Yes'],
            closeOnEsc: false,
            closeOnClickOutside: false
          })
          .then(stat => {
            if(stat) {
              this.generateProduct(json.data);
            }
          });
          
        })
        .catch(err => {
          this.loading = false;
          if(err.response.status > 300 && err.response.status < 500) {
            this.alert = err.response.data.message;
          }
        })
    },
    generateProduct(params) {
      swal('Generating...', 'This process will take a few moments.', {
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: false
      });
      axios.post('/member/package', { type: 'generate', data: params })
        .then((json) => {
          this.loading = false;
          window.location.href = json.data.redirect;
        })
        .catch(err => {
          swal.close();
          this.loading = false;
          if(err.response.status > 300 && err.response.status < 500) {
            this.alert = err.response.data.message;
          }
        })
    }
  }
}
</script>